import React, { useState } from "react";
import { useEffect } from "react";
import axiosInstance from "../../../utils/axiosInstance";
import Modal from "./Modal";
import Table from "./Table";

export default function Content({ formSubmitted }) {
  const [isModalOpen, setModalOpen] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [recruitments, setRecruitments] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [filter, setFilter] = useState("All Division");
  const [modalContent, setModalContent] = useState({});

  useEffect(() => {
    setTimeout(() => {
      if (!formSubmitted) return;
      axiosInstance.get("/recruitment").then((response) => {
        setRecruitments(response.data.data);
      });
      setLoading(false);
    }, 1000);
  }, [formSubmitted]);

  const handleModal = (nrp) => {
    setModalContent(recruitments.find((recruitment) => recruitment.nrp === nrp));
    setModalOpen(true);
  };

  const filteredRecruitments = recruitments.filter((recruitment) => {
    const divisionMatch = filter !== "All Division" ? recruitment.division === filter : true;
    const nameMatch = recruitment.name.includes(keyword);
    return divisionMatch && nameMatch;
  });

  return (
    <div className="container mx-auto px-4 py-8 md:p-12 min-h-screen max-w-screen-xl">
      <h1 className="mb-8 text-3xl font-bold">Recruitments</h1>
      <p className="mb-2 text-xl font-bold">
        Total : {filteredRecruitments.length} / {recruitments.length}
      </p>
      <div className="flex flex-row gap-x-6 flex-wrap items-center justify-between mb-6">
        <select id="division" className="bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-teal-500 focus:border-teal-500 block p-4" onChange={(e) => setFilter(e.target.value)}>
          <option value="All Division">All Division</option>
          <option value="Reporter">Reporter</option>
          <option value="Videographer">Videographer</option>
          <option value="Photographer">Photographer</option>
          <option value="Graphic Designer">Graphic Designer</option>
          <option value="Webmaster">Webmaster</option>
        </select>

        <div className="flex-1 max-w-lg">
          <div className="relative">
            <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
              <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
              </svg>
            </div>
            <input
              type="search"
              id="search"
              className="block w-full p-4 ps-10 text-base text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-teal-500 focus:border-teal-500"
              placeholder="Search Name..."
              value={keyword}
              onInput={(e) => setKeyword(e.target.value)}
            />
          </div>
        </div>
      </div>

      <div className="bg-white rounded-md shadow overflow-x-auto">
        <Table filteredRecruitments={filteredRecruitments} handleModal={handleModal} isLoading={isLoading} />
      </div>

      {isModalOpen && <Modal modalContent={modalContent} setModalOpen={setModalOpen} />}
    </div>
  );
}
