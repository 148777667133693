export default function Table({
  filteredRecruitments,
  handleModal,
  isLoading,
}) {
  return (
    <table className="w-full whitespace-nowrap">
      <thead>
        <tr className="text-left font-bold">
          <th className="pb-4 pt-6 px-6">Name</th>
          <th className="pb-4 pt-6 px-6">NRP</th>
          <th className="pb-4 pt-6 px-6" colSpan="2">
            Divisi
          </th>
        </tr>
      </thead>
      <tbody>
        {filteredRecruitments.map((recruitment, idx) => (
          <tr className="hover:bg-gray-100 focus-within:bg-gray-100" key={idx}>
            <td className="border-t">
              <button
                className="flex items-center px-6 py-4 w-full"
                onClick={() => handleModal(recruitment.nrp)}
              >
                {recruitment.name}
              </button>
            </td>
            <td className="border-t">
              <button
                className="flex items-center px-6 py-4 w-full"
                tabIndex={-1}
                onClick={() => handleModal(recruitment.nrp)}
              >
                {recruitment.nrp}
              </button>
            </td>
            <td className="border-t">
              <button
                className="flex items-center px-6 py-4 w-full"
                tabIndex={-1}
                onClick={() => handleModal(recruitment.nrp)}
              >
                {recruitment.division}
              </button>
            </td>
            <td className="w-px border-t">
              <button
                onClick={() => handleModal(recruitment.nrp)}
                className="flex items-center px-4 w-full"
                tabIndex={-1}
              >
                <svg
                  className="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m9 5 7 7-7 7"
                  />
                </svg>
              </button>
            </td>
          </tr>
        ))}

        {isLoading && (
          <tr className="text-center">
            <td className="px-6 py-4 border-t" colSpan="4">
              Loading ...
            </td>
          </tr>
        )}

        {!isLoading && filteredRecruitments.length === 0 && (
          <tr className="text-center">
            <td className="px-6 py-4 border-t" colSpan="4">
              No contacts found.
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
}
