import React, { useState, useEffect } from 'react';
import axiosInstance from '../../../utils/axiosInstance';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

import FormLayout from '../../../layouts/FormLayout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronCircleLeft } from '@fortawesome/free-solid-svg-icons';
import bgToTop from '../../../asset/pattern4-totop.svg';
import bgToBottom from '../../../asset/pattern4-tobottom.svg';

const ResponsiveFormWithNavbar = () => {
  const [isLoading, setLoading] = useState(false);

  const navigate = useNavigate();
  const requiredFields = [
    'name',
    'placeOfBirth',
    'dateOfBirth',
    'boardingAddress',
    'homeAddress',
    'nrp',
    'email',
    'phone',
    'description',
    'motto',
    'interest',
    'reason',
    // 'portofolio',
  ];

  const [currentSection, setCurrentSection] = useState('personal'); // 'personal', 'experience', 'achievement'

  const [formData, setFormData] = useState({
    name: '',
    place_of_birth: '',
    date_of_birth: '',
    gender: '--Option--',
    boarding_address: '',
    home_address: '',
    religion: '--Option--',
    nrp: '',
    strata: '--Option--',
    prodi: '--Option--',
    email: '',
    phone: '',
    description: '',
    mbti: '-',
    motto: '',
    interest: '',
    division: '--Option--',
    reason: '',
    url_portofolio: '',
    experiences: [
      { start_date: '-', end_date: '-', organization_name: '', position: '' },
    ],
    achievements: [{ date: '-', title: '', level: '', achievement: '' }],
  });

  // PERSONAL
  const handleSubmit = async (e) => {
    setLoading(true);
    setCurrentSection('achievement');
    e.preventDefault();

    // cek experience
    if (
      JSON.stringify(formData.experiences[0]) ===
      JSON.stringify({
        start_date: '-',
        end_date: '-',
        organization_name: '',
        position: '',
      })
    ) {
      formData.experiences = [];
    }

    // cek experience
    if (
      JSON.stringify(formData.achievements[0]) ===
      JSON.stringify({ date: '-', title: '', level: '', achievement: '' })
    ) {
      formData.achievements = [];
    }

    try {
      await axiosInstance.post('/recruitment', formData);
      alert('Pendaftaran Berhasil');
      navigate('/participant');
    } catch (error) {
      alert('Your Submit form Failed. Please try again or contact admin');
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // EXPERIENCE
  const handleExperienceChange = (index, field, value) => {
    const updatedExperiences = formData.experiences.map((experience, i) => {
      if (i === index) {
        return { ...experience, [field]: value };
      }
      return experience;
    });

    setFormData({
      ...formData,
      experiences: updatedExperiences,
    });
    // console.log(formData);
  };

  const addExperienceField = () => {
    setFormData((prevData) => ({
      ...prevData,
      experiences: [
        ...prevData.experiences,
        { start_date: '', end_date: '', organization_name: '', position: '' },
      ],
    }));
  };

  const removeExperienceField = (index) => {
    const updatedExperiences = formData.experiences.filter(
      (experience, i) => i !== index,
    );
    setFormData({
      ...formData,
      experiences: updatedExperiences,
    });
  };

  // ACHIEVEMENT
  const handleAchievementChange = (index, field, value) => {
    const updatedAchievements = formData.achievements.map((achievement, i) => {
      if (i === index) {
        return { ...achievement, [field]: value };
      }
      return achievement;
    });

    setFormData({
      ...formData,
      achievements: updatedAchievements,
    });
  };

  const addAchievementField = () => {
    setFormData((prevData) => ({
      ...prevData,
      achievements: [
        ...prevData.achievements,
        { date: '', title: '', level: '', achievement: '' },
      ],
    }));
  };

  const removeAchievementField = (index) => {
    const updatedAchievements = formData.achievements.filter(
      (achievement, i) => i !== index,
    );
    setFormData({
      ...formData,
      achievements: updatedAchievements,
    });
  };

  // Use local storage to save and load form data
  useEffect(() => {
    const storedData = localStorage.getItem('form_data');

    if (storedData) {
      const parsedData = JSON.parse(storedData);
      setCurrentSection(parsedData.currentSection);
    }
  }, []);

  useEffect(() => {
    if (currentSection === 'personal') {
      // Update input fields for the personal section with values from state
      const nameField = document.getElementById('name');
      const placeOfBirthField = document.getElementById('placeOfBirth');
      const dateOfBirthField = document.getElementById('dateOfBirth');
      const boardingAddressField = document.getElementById('boardingAddress');
      const homeAddressField = document.getElementById('homeAddress');
      const nrpField = document.getElementById('nrp');
      const emailField = document.getElementById('email');
      const phoneField = document.getElementById('phone');
      const descriptionField = document.getElementById('description');
      const mottoField = document.getElementById('motto');
      const interestField = document.getElementById('interest');
      const reasonField = document.getElementById('reason');
      const portofolioField = document.getElementById('linkDrive');
      // ... and other fields

      if (nameField) nameField.value = formData.name;
      if (placeOfBirthField) placeOfBirthField.value = formData.place_of_birth;
      if (dateOfBirthField) dateOfBirthField.value = formData.date_of_birth;
      if (boardingAddressField)
        boardingAddressField.value = formData.boarding_address;
      if (homeAddressField) homeAddressField.value = formData.home_address;
      if (nrpField) nrpField.value = formData.nrp;
      if (emailField) emailField.value = formData.email;
      if (phoneField) phoneField.value = formData.phone;
      if (descriptionField) descriptionField.value = formData.description;
      if (mottoField) mottoField.value = formData.motto;
      if (interestField) interestField.value = formData.interest;
      if (reasonField) reasonField.value = formData.reason;
      if (portofolioField) portofolioField.value = formData.url_portofolio;

      // ... update other fields as needed
    } else if (currentSection === 'experience') {
      // Update input fields for the experience section with values from experienceFields
      // ... update input fields for experience section
    } else if (currentSection === 'achievement') {
      // Update input fields for the achievement section with values from achievementFields
      // ... update input fields for achievement section
    }
  }, [
    currentSection,
    formData.name,
    formData.place_of_birth,
    formData.date_of_birth,
    formData.boarding_address,
    formData.home_address,
    formData.nrp,
    formData.email,
    formData.phone,
    formData.description,
    formData.motto,
    formData.interest,
    formData.reason,
    formData.url_portofolio,
  ]);

  return (
    <FormLayout>
      <div className="p-4 pt-20 md:p-8 md:pt-24 lg:pt-28 lg:p-16 bg-hero text-white relative z-10">
        <div className="flex flex-col absolute inset-0 justify-between -z-10">
          <div
            className="h-40 md:h-80 md:bg-repeat-x top-0"
            style={{
              backgroundImage: `url(${bgToTop})`,
              backgroundSize: 'contain',
            }}
          ></div>
          <div
            className="h-40 md:h-80 md:bg-repeat-x bottom-0"
            style={{
              backgroundImage: `url(${bgToBottom})`,
              backgroundSize: 'contain',
            }}
          ></div>
        </div>

        {/* Form */}
        <div className="container mx-auto max-w-screen-lg">
          <form
            onSubmit={handleSubmit}
            className="mx-auto text-white rounded px-8 pt-6 pb-8"
          >
            {/* Personal Information section */}
            {currentSection === 'personal' && (
              <div>
                <Link to={'/requirement'}>
                  <FontAwesomeIcon
                    icon={faChevronCircleLeft}
                    className="text-xl hover:text-teal-500 h-6 text-white "
                  />
                </Link>
                <h2 className="text-3xl font-bold mb-5 text-center">
                  PERSONAL FORM
                </h2>

                <div className="mb-5">
                  <label
                    htmlFor="name"
                    className="block mb-2 font-medium text-white"
                  >
                    Name
                  </label>
                  <input
                    autoComplete="off"
                    className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg block w-full p-2.5"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    type="text"
                    placeholder="Enter your name..."
                    required
                  />
                </div>

                <div className="grid gap-x-5 md:grid-cols-2">
                  <div className="grid gap-x-5 sm:grid-cols-2">
                    <div className="mb-5">
                      <label
                        htmlFor="placeOfBirth"
                        className="block mb-2 font-medium text-white"
                      >
                        Place of Birth
                      </label>
                      <input
                        autoComplete="off"
                        className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg block w-full p-2.5"
                        id="placeOfBirth"
                        name="place_of_birth"
                        value={formData.place_of_birth}
                        onChange={handleInputChange}
                        type="text"
                        placeholder="Enter your place of birth..."
                        required
                      />
                    </div>

                    <div className="mb-5">
                      <label
                        htmlFor="dateOfBirth"
                        className="block mb-2 font-medium text-white"
                      >
                        Date of Birth
                      </label>
                      <input
                        autoComplete="off"
                        className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg block w-full p-2.5"
                        id="dateOfBirth"
                        name="date_of_birth"
                        value={formData.date_of_birth}
                        onChange={handleInputChange}
                        type="date"
                        placeholder="Enter your date of birth..."
                        required
                      />
                    </div>
                  </div>
                  <div className="grid gap-x-5 md:grid-cols-2">
                    <div className="mb-5">
                      <label
                        htmlFor="gender"
                        className="block mb-2 font-medium text-white"
                      >
                        Gender
                      </label>
                      <select
                        className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        id="gender"
                        name="gender"
                        value={formData.gender}
                        onChange={handleInputChange}
                      >
                        <option value="--Option--">--Option--</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                      </select>
                    </div>

                    <div className="mb-5">
                      <label
                        htmlFor="religion"
                        className="block mb-2 font-medium text-white"
                      >
                        Religion
                      </label>
                      <select
                        className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        id="religion"
                        name="religion"
                        value={formData.religion}
                        onChange={handleInputChange}
                      >
                        <option value="--Option--">--Option--</option>
                        <option value="Islam">Islam</option>
                        <option value="Protestant">Protestant</option>
                        <option value="Catholic">Catholic</option>
                        <option value="Hindu">Hindu</option>
                        <option value="Buddha">Buddha</option>
                        <option value="Confucius">Confucius</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="flex flex-wrap">
                  <div className="mb-5 w-full md:w-1/4">
                    <label
                      htmlFor="nrp"
                      className="block mb-2 font-medium text-white"
                    >
                      NRP
                    </label>
                    <input
                      autoComplete="off"
                      className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg block w-full p-2.5"
                      id="nrp"
                      name="nrp"
                      value={formData.nrp}
                      onChange={handleInputChange}
                      type="text"
                      minLength={10}
                      maxLength={10}
                      placeholder="Enter your NRP..."
                    />
                  </div>

                  <div className="mb-5 w-full md:w-2/4 md:px-5">
                    <label
                      htmlFor="email"
                      className="block mb-2 font-medium text-white"
                    >
                      Email PENS
                    </label>
                    <input
                      autoComplete="off"
                      className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg block w-full p-2.5"
                      id="email"
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                      type="email"
                      placeholder="Enter your email..."
                      required
                    />
                  </div>

                  <div className="mb-5 w-full md:w-1/4">
                    <label
                      htmlFor="phone"
                      className="block mb-2 font-medium text-white"
                    >
                      Whatsapp Number
                    </label>
                    <input
                      autoComplete="off"
                      className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg block w-full p-2.5"
                      id="phone"
                      name="phone"
                      value={formData.phone}
                      onChange={handleInputChange}
                      type="text"
                      placeholder="Enter your phone number..."
                      required
                    />
                  </div>
                </div>

                <div className="grid md:gap-6 md:grid-cols-2">
                  <div className="mb-5">
                    <label
                      htmlFor="educationalLevel"
                      className="block mb-2 font-medium text-white"
                    >
                      Educational Level
                    </label>
                    <select
                      className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      id="educationalLevel"
                      name="strata"
                      value={formData.strata}
                      onChange={handleInputChange}
                    >
                      <option value="--Option--">--Option--</option>
                      <option value="D4">APPLIED BACHELOR (D4) </option>
                      <option value="D3">3-YEAR DIPLOMA (D3)</option>
                    </select>
                  </div>

                  <div className="mb-5">
                    <label
                      htmlFor="studyProgram"
                      className="block mb-2 font-medium text-white"
                    >
                      Study Program
                    </label>
                    <select
                      className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      id="studyProgram"
                      name="prodi"
                      value={formData.prodi}
                      onChange={handleInputChange}
                    >
                      <option value="--Option--">--Option--</option>

                      <option disabled className="font-medium">
                        Department of Electrical Engineering
                      </option>
                      <option value="D3 Electrical Engineering">
                        D3 Electrical Engineering
                      </option>
                      <option value="D3 Industrial Electrical Engineering">
                        D3 Industrial Electrical Engineering
                      </option>
                      <option value="D3 Telecommunications Engineering">
                        D3 Telecommunications Engineering
                      </option>

                      <option value="D4 Electrical Engineering">
                        D4 Electrical Engineering
                      </option>
                      <option value="D4 Industrial Electrical Engineering">
                        D4 Industrial Electrical Engineering
                      </option>
                      <option value="D4 Telecommunications Engineering">
                        D4 Telecommunications Engineering
                      </option>

                      <option value="D4 Internet Engineering Technology">
                        D4 Internet Engineering Technology
                      </option>

                      <option disabled className="font-medium">
                        Department of Informatics and Computer Engineering
                      </option>
                      <option value="D3 Informatics Engineering">
                        D3 Informatics Engineering
                      </option>
                      <option value="D4 Informatics Engineering">
                        D4 Informatics Engineering
                      </option>
                      <option value="D4 Computer Engineering">
                        D4 Computer Engineering
                      </option>
                      <option value="D4 Applied Data Science">
                        D4 Applied Data Science
                      </option>

                      <option disabled className="font-medium">
                        Department of Energy Mechanics Engineering
                      </option>
                      <option value="D4 Mechatronic Engineering">
                        D4 Mechatronic Engineering
                      </option>
                      <option value="D4 Energy Generation System">
                        D4 Energy Generation System
                      </option>

                      <option disabled className="font-medium">
                        Creative Multimedia Technology Department
                      </option>
                      <option value="D3 Multimedia Broadcasting">
                        D3 Multimedia Broadcasting
                      </option>
                      <option value="D4 Multimedia Engineering Technology">
                        D4 Multimedia Engineering Technology
                      </option>
                      <option value="D4 Game Technology">
                        D4 Game Technology
                      </option>
                    </select>
                  </div>
                </div>

                <div className="mb-5">
                  <label
                    htmlFor="boardingAddress"
                    className="block mb-2 font-medium text-white"
                  >
                    Boarding Address
                  </label>
                  <textarea
                    autoComplete="off"
                    className="block p-2.5 w-full text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                    id="boardingAddress"
                    name="boarding_address"
                    rows="3"
                    maxLength={45}
                    value={formData.boarding_address}
                    onChange={handleInputChange}
                    placeholder="Enter your boarding address..."
                  ></textarea>
                </div>

                <div className="mb-5">
                  <label
                    htmlFor="homeAddress"
                    className="block mb-2 font-medium text-white"
                  >
                    Home Address
                  </label>
                  <textarea
                    autoComplete="off"
                    className="block p-2.5 w-full text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                    id="homeAddress"
                    name="home_address"
                    rows="3"
                    maxLength={45}
                    value={formData.home_address}
                    onChange={handleInputChange}
                    placeholder="Enter your home address..."
                  ></textarea>
                </div>

                <div className="mb-5">
                  <label
                    htmlFor="description"
                    className="block mb-2 font-medium text-white"
                  >
                    Description Yourself
                  </label>
                  <textarea
                    autoComplete="off"
                    className="block p-2.5 w-full text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                    id="description"
                    name="description"
                    rows="3"
                    value={formData.description}
                    onChange={handleInputChange}
                    placeholder="Enter your description..."
                  ></textarea>
                </div>

                <div className="mb-5">
                  <label
                    htmlFor="motto"
                    className="block mb-2 font-medium text-white"
                  >
                    Motto
                  </label>
                  <textarea
                    autoComplete="off"
                    className="block p-2.5 w-full text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                    id="motto"
                    name="motto"
                    rows="3"
                    maxLength={45}
                    value={formData.motto}
                    onChange={handleInputChange}
                    placeholder="Enter your motto..."
                  ></textarea>
                </div>

                <div className="mb-5">
                  <label
                    htmlFor="interest"
                    className="block mb-2 font-medium text-white"
                  >
                    Interest to Join Another Organization or UKM
                  </label>
                  <textarea
                    autoComplete="off"
                    className="block p-2.5 w-full text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                    id="interest"
                    name="interest"
                    rows="3"
                    maxLength={45}
                    value={formData.interest}
                    onChange={handleInputChange}
                    placeholder="Enter your interests..."
                  ></textarea>
                  <p
                    id="helper-text-explanation"
                    className="mt-2 text-sm text-gray-400"
                  >
                    If yes, explain which UKM you want to join
                  </p>
                </div>

                <div className="mb-5">
                  <label
                    htmlFor="division"
                    className="block mb-2 font-medium text-white"
                  >
                    Divison
                  </label>
                  <select
                    className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    id="division"
                    name="division"
                    value={formData.division}
                    onChange={handleInputChange}
                  >
                    <option value="--Option--">--Option--</option>
                    <option value="Reporter">Reporter</option>
                    <option value="Videographer">Videographer</option>
                    <option value="Photographer">Photographer</option>
                    <option value="Graphic Designer">Graphic Designer</option>
                    <option value="Webmaster">Webmaster</option>
                  </select>
                </div>

                <div className="mb-5">
                  <label
                    htmlFor="reason"
                    className="block mb-2 font-medium text-white"
                  >
                    Reason Join ENT
                  </label>
                  <textarea
                    autoComplete="off"
                    className="block p-2.5 w-full text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                    id="reason"
                    name="reason"
                    rows="3"
                    maxLength={45}
                    value={formData.reason}
                    onChange={handleInputChange}
                    placeholder="Enter your reason..."
                  ></textarea>
                </div>

                <div className="mt-4 flex justify-end">
                  <button
                    type="button"
                    onClick={() => {
                      let hasEmptyFields = false;

                      for (const fieldId of requiredFields) {
                        const fieldElement = document.getElementById(fieldId);

                        if (!fieldElement.value) {
                          alert(
                            `Please fill in the ${fieldElement.placeholder}.`,
                          );
                          fieldElement.focus();
                          hasEmptyFields = true;
                          break;
                        }
                      }

                      if (hasEmptyFields) {
                        return;
                      }

                      //   console.log(formData.gender);
                      if (formData.gender === '--Option--') {
                        alert(
                          'Please select a gender option from the dropdown before proceeding.',
                        );
                        return;
                      }

                      if (formData.religion === '--Option--') {
                        alert(
                          'Please select a religion option from the dropdown before proceeding.',
                        );
                        return;
                      }

                      if (formData.strata === '--Option--') {
                        alert(
                          'Please select a educational level option from the dropdown before proceeding.',
                        );
                        return;
                      }

                      if (formData.prodi === '--Option--') {
                        alert(
                          'Please select a study program option from the dropdown before proceeding.',
                        );
                        return;
                      }

                      // if (formData.mbti === '--Option--') {
                      //   alert(
                      //     'Please select a mbti option from the dropdown before proceeding.',
                      //   );
                      //   return;
                      // }

                      if (formData.division === '--Option--') {
                        alert(
                          'Please select a division option from the dropdown before proceeding.',
                        );
                        return;
                      }

                      setCurrentSection('experience');
                    }}
                    className="text-white bg-teal-500 hover:bg-teal-600 focus:ring-4 focus:ring-teal-300 font-medium rounded-lg px-5 py-2.5 focus:outline-none"
                  >
                    Next
                  </button>
                  {/* <button
                  onClick={() => setCurrentSection('experience')}
                  className="px-4 py-2 bg-teal-500 text-white rounded-md hover:bg-teal-700 focus:shadow-outline"
                >
                  Next
                </button> */}
                </div>
              </div>
            )}

            {/* Experience section */}
            {currentSection === 'experience' && (
              <div>
                {/* Experience section */}
                <div className="mt-6">
                  <h2 className="text-3xl font-bold text-center mb-5">
                    FORM EXPERIENCE
                  </h2>
                  {formData.experiences.map((experience, index) => (
                    <div key={index} className="mb-6">
                      <h3 className="block text-lg font-medium mb-2">
                        [Experience {index + 1}]
                      </h3>

                      <div className="grid gap-x-6 grid-cols-2">
                        <div className="mb-5">
                          <label className="block mb-2 font-medium text-white">
                            Start Date
                          </label>
                          <input
                            type="month"
                            className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg block w-full p-2.5"
                            value={experience.start_date}
                            onChange={(e) =>
                              handleExperienceChange(
                                index,
                                'start_date',
                                e.target.value,
                              )
                            }
                          />
                        </div>

                        <div className="mb-5">
                          <label className="block mb-2 font-medium text-white">
                            End Date
                          </label>
                          <input
                            type="month"
                            className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg block w-full p-2.5"
                            value={experience.end_date}
                            onChange={(e) =>
                              handleExperienceChange(
                                index,
                                'end_date',
                                e.target.value,
                              )
                            }
                          />
                        </div>
                      </div>
                      <div className="mb-5">
                        <label
                          htmlFor="organization"
                          className="block mb-2 font-medium text-white"
                        >
                          Organization
                        </label>
                        <input
                          autoComplete="off"
                          className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg block w-full p-2.5"
                          id="organization"
                          name="organization"
                          value={experience.organization_name}
                          type="text"
                          placeholder="Organization name..."
                          onChange={(e) =>
                            handleExperienceChange(
                              index,
                              'organization_name',
                              e.target.value,
                            )
                          }
                        />
                      </div>

                      <div className="mb-5">
                        <label
                          htmlFor="position"
                          className="block mb-2 font-medium text-white"
                        >
                          Position
                        </label>
                        <input
                          autoComplete="off"
                          className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg block w-full p-2.5"
                          id="position"
                          name="position"
                          value={experience.position}
                          type="text"
                          placeholder="Your Position..."
                          onChange={(e) =>
                            handleExperienceChange(
                              index,
                              'position',
                              e.target.value,
                            )
                          }
                        />
                      </div>

                      {formData.experiences.length > 1 ? (
                        <button
                          type="button"
                          onClick={() => removeExperienceField(index)}
                          className="focus:outline-none text-white bg-red-600 hover:bg-red-700 focus:ring-4 focus:ring-red-900 font-medium rounded-lg px-5 py-2.5"
                        >
                          Remove
                        </button>
                      ) : null}
                    </div>
                  ))}
                  {/* selama iki true show lek false hide */}
                  {/*haruse lek kurang dari 2 baru hide */}
                  {/* 1 > 1 */}

                  {/* selama iki true show lek false hide */}
                  {/*haruse lek lebih dari 3 baru hide */}
                  {formData.experiences.length <= 2 ? (
                    <button
                      type="button"
                      onClick={addExperienceField}
                      className="text-white bg-teal-500 hover:bg-teal-600 focus:ring-4 focus:ring-teal-300 font-medium rounded-lg px-5 py-2.5 focus:outline-none"
                    >
                      Add
                    </button>
                  ) : null}
                </div>

                <div className="mt-4 flex justify-between">
                  <button
                    onClick={() => setCurrentSection('personal')}
                    className="text-gray-600 bg-gray-300 hover:bg-gray-400 focus:ring-4 focus:ring-gray-300 font-medium rounded-lg px-5 py-2.5 focus:outline-none"
                  >
                    Previous
                  </button>
                  <button
                    onClick={() => setCurrentSection('achievement')}
                    className="text-white bg-teal-500 hover:bg-teal-600 focus:ring-4 focus:ring-teal-300 font-medium rounded-lg px-5 py-2.5 focus:outline-none"
                  >
                    Next
                  </button>
                </div>
              </div>
            )}

            {/* Achievement section */}
            {currentSection === 'achievement' && (
              <div>
                {/* Achievement section */}
                <div className="mt-6">
                  <h2 className="text-3xl font-bold text-center mb-5">
                    FORM ACHIEVEMENT
                  </h2>
                  {formData.achievements.map((achievement, index) => (
                    <div key={index} className="mb-4">
                      <h3
                        className="block text-lg font-medium mb-2"
                        htmlFor="achievement"
                      >
                        [Achievement {index + 1}]
                      </h3>

                      <div className="mb-5">
                        <label className="block mb-2 font-medium text-white">
                          Date
                        </label>
                        <input
                          type="date"
                          className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg block w-full p-2.5"
                          value={achievement.date}
                          onChange={(e) =>
                            handleAchievementChange(
                              index,
                              'date',
                              e.target.value,
                            )
                          }
                        />
                      </div>

                      <div className="mb-5">
                        <label className="block mb-2 font-medium text-white">
                          Title
                        </label>
                        <input
                          className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg block w-full p-2.5"
                          value={achievement.title}
                          type="text"
                          placeholder="Title"
                          onChange={(e) =>
                            handleAchievementChange(
                              index,
                              'title',
                              e.target.value,
                            )
                          }
                        />
                      </div>

                      <div className="mb-5">
                        <label className="block mb-2 font-medium text-white">
                          Level
                        </label>
                        <select
                          className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                          value={achievement.level}
                          onChange={(e) =>
                            handleAchievementChange(
                              index,
                              'level',
                              e.target.value,
                            )
                          }
                        >
                          <option value="">--Option--</option>
                          <option value="local">
                            Local District or Regency/City Level
                          </option>
                          <option value="provincial">Provincial Level</option>
                          <option value="national">National Level</option>
                          <option value="international">
                            International Level
                          </option>
                        </select>
                      </div>

                      <div className="mb-5">
                        <label className="block mb-2 font-medium text-white">
                          Achievement Description
                        </label>
                        <input
                          className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg block w-full p-2.5"
                          value={achievement.achievement}
                          type="text"
                          placeholder="Achievement"
                          onChange={(e) =>
                            handleAchievementChange(
                              index,
                              'achievement',
                              e.target.value,
                            )
                          }
                        />
                      </div>

                      {formData.achievements.length > 1 ? (
                        <button
                          type="button"
                          onClick={() => removeAchievementField(index)}
                          className="focus:outline-none text-white bg-red-600 hover:bg-red-700 focus:ring-4 focus:ring-red-900 font-medium rounded-lg px-5 py-2.5"
                        >
                          Remove
                        </button>
                      ) : null}
                    </div>
                  ))}

                  {/* selama iki true show lek false hide */}
                  {/*haruse lek kurang dari 2 baru hide */}
                  {/* 1 > 1 */}

                  {/* selama iki true show lek false hide */}
                  {/*haruse lek lebih dari 3 baru hide */}
                  {formData.achievements.length <= 2 ? (
                    <button
                      type="button"
                      onClick={addAchievementField}
                      className="text-white bg-teal-500 hover:bg-teal-600 focus:ring-4 focus:ring-teal-300 font-medium rounded-lg px-5 py-2.5 focus:outline-none"
                    >
                      Add
                    </button>
                  ) : null}
                </div>

                <div className="my-5">
                  <label
                    htmlFor="linkDrive"
                    className="block mb-2 font-medium text-white"
                  >
                    Link Drive Portofolio
                  </label>
                  <input
                    autoComplete="off"
                    className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg block w-full p-2.5"
                    id="linkDrive"
                    name="url_portofolio"
                    type="text"
                    value={formData.url_portofolio}
                    onChange={handleInputChange}
                    placeholder="Enter your link drive..."
                    required
                  />
                </div>

                <div className="flex justify-between">
                  <button
                    type="button"
                    onClick={() => setCurrentSection('experience')}
                    className="text-gray-600 bg-gray-300 hover:bg-gray-400 focus:ring-4 focus:ring-gray-300 font-medium rounded-lg px-5 py-2.5 focus:outline-none"
                  >
                    Previous
                  </button>
                  {isLoading ? (
                    <button
                      disabled
                      type="button"
                      className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 inline-flex items-center"
                    >
                      <svg
                        aria-hidden="true"
                        role="status"
                        className="inline w-4 h-4 me-3 text-white animate-spin"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="#E5E7EB"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentColor"
                        />
                      </svg>
                      Loading...
                    </button>
                  ) : (
                    <button
                      type="submit"
                      className="text-white bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg px-5 py-2.5 focus:outline-none"
                    >
                      Submit
                    </button>
                  )}
                </div>
              </div>
            )}
          </form>
        </div>
      </div>
    </FormLayout>
  );
};

export default ResponsiveFormWithNavbar;
