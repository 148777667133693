export default function Modal({ modalContent, setModalOpen }) {
  return (
    <div className="fixed inset-0 z-50 flex justify-center items-center backdrop-blur-sm bg-opacity-25">
      <div className="bg-white rounded-lg shadow dark:bg-gray-700 w-full max-w-screen-md">
        <div className="flex items-center justify-between p-4 md:p-5 rounded-t">
          <h3 className="text-xl font-semibold text-gray-900">
            {modalContent.name} | {modalContent.division} |{' '}
            <a
              href={modalContent.url_portofolio}
              target={'_blank'}
              rel="noreferrer"
              className="font-medium text-teal-600 dark:text-teal-500 hover:underline"
            >
              Portofolio
            </a>
          </h3>
          <button
            type="button"
            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
            onClick={() => setModalOpen(false)}
          >
            <svg
              className="w-3 h-3"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 14"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
              />
            </svg>
          </button>
        </div>
        <div className="p-4 md:p-5 space-y-4">
          <dl className="text-gray-900 divide-y divide-gray-200 dark:text-white dark:divide-gray-700 overflow-y-hidden">
            <div className="flex flex-col pt-3">
              <dt className="mb-1 text-gray-500 md:text-lg dark:text-gray-400">
                Study Program
              </dt>
              <dd className="text-lg font-semibold">{modalContent.prodi}</dd>
            </div>
            <div className="flex flex-col pt-3">
              <dt className="mb-1 text-gray-500 md:text-lg dark:text-gray-400">
                Email PENS
              </dt>
              <dd className="text-lg font-semibold">{modalContent.email}</dd>
            </div>
            <div className="flex flex-col pt-3">
              <dt className="mb-1 text-gray-500 md:text-lg dark:text-gray-400">
                Phone
              </dt>
              <dd className="text-lg font-semibold">{modalContent.phone}</dd>
            </div>
            <div className="flex flex-col pt-3">
              <dt className="mb-1 text-gray-500 md:text-lg dark:text-gray-400">
                Description Yourself
              </dt>
              <dd className="text-lg font-semibold">
                {modalContent.description}
              </dd>
            </div>
            <div className="flex flex-col pt-3">
              <dt className="mb-1 text-gray-500 md:text-lg dark:text-gray-400">
                Motto
              </dt>
              <dd className="text-lg font-semibold">{modalContent.motto}</dd>
            </div>
            <div className="flex flex-col pt-3">
              <dt className="mb-1 text-gray-500 md:text-lg dark:text-gray-400">
                Interest to Join Another Organization or UKM
              </dt>
              <dd className="text-lg font-semibold">{modalContent.interest}</dd>
            </div>
            <div className="flex flex-col pt-3">
              <dt className="mb-1 text-gray-500 md:text-lg dark:text-gray-400">
                Reason Join ENT
              </dt>
              <dd className="text-lg font-semibold">{modalContent.reason}</dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  );
}
