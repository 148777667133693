import React, { useState } from 'react';
// import FormLayout from "../../../layouts/FormLayout"
import { Link } from 'react-router-dom';
import bgToTop from '../../../asset/pattern4-totop.svg';
import bgToBottom from '../../../asset/pattern4-tobottom.svg';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../../utils/axiosInstance';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronCircleLeft } from '@fortawesome/free-solid-svg-icons';

const CekParticipant = () => {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    email: '',
    nrp: '',
  });
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await axiosInstance.post(
        '/cekrecruitment',
        formData,
        {},
      );
      if (response.status === 200) {
        navigate('/participant/' + response.data.id);
      }
      // You can handle the response data here
    } catch (error) {
      alert('Mohon maaf data Anda tidak terdaftar');

      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <section className="bg-[#E6E3E1] relative z-10">
      <div className="flex flex-col absolute inset-0 justify-between -z-10">
        <div
          className="h-40 md:h-56 md:bg-repeat-x top-0"
          style={{
            backgroundImage: `url(${bgToBottom})`,
            backgroundSize: 'contain',
          }}
        ></div>
        <div
          className="h-40 md:h-56 md:bg-repeat-x bottom-0"
          style={{
            backgroundImage: `url(${bgToTop})`,
            backgroundSize: 'contain',
          }}
        ></div>
      </div>

      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto h-screen lg:py-0">
        <div className="w-full bg-hero rounded-lg shadow md:mt-0 sm:max-w-md xl:p-0">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <div className="flex justify-items-center items-center">
              <Link to={'/requirement'} className="align-middle me-2">
                <FontAwesomeIcon
                  icon={faChevronCircleLeft}
                  className="text-xl hover:text-teal-500 h-6 text-white "
                />
              </Link>
              <h1 className="text-xl font-bold leading-tight tracking-tight md:text-2xl text-white text-center w-full">
                Generate Form
              </h1>
            </div>
            <form onSubmit={handleSubmit} className="space-y-4 md:space-y-6">
              <div>
                <label
                  htmlFor="email"
                  className="block mb-2 font-medium text-white"
                >
                  Email PENS
                </label>
                <input
                  autoComplete="off"
                  className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg block w-full p-2.5"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  type="text"
                  placeholder="Enter your email student..."
                  required
                />
              </div>
              <div>
                <label
                  htmlFor="nrp"
                  className="block mb-2 font-medium text-white"
                >
                  NRP
                </label>
                <input
                  autoComplete="off"
                  className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg block w-full p-2.5"
                  id="nrp"
                  name="nrp"
                  value={formData.nrp}
                  onChange={handleInputChange}
                  type="text"
                  placeholder="Enter your nrp..."
                  required
                />
              </div>

              {isLoading ? (
                <button
                  disabled
                  type="button"
                  className="w-full text-white bg-teal-500 hover:bg-teal-600 focus:ring-4 focus:ring-teal-300 font-medium rounded-lg px-5 py-2.5 focus:outline-none"
                >
                  <svg
                    aria-hidden="true"
                    role="status"
                    className="inline w-4 h-4 me-3 text-white animate-spin"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="#E5E7EB"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentColor"
                    />
                  </svg>
                  Loading...
                </button>
              ) : (
                <button
                  type="submit"
                  className="w-full text-white bg-teal-500 hover:bg-teal-600 focus:ring-4 focus:ring-teal-300 font-medium rounded-lg px-5 py-2.5 focus:outline-none"
                >
                  Submit
                </button>
              )}
              <p className="text-sm text-white">
                <Link
                  to={'/registration'}
                  className="font-medium hover:underline text-yellow-400"
                >
                  Click Here
                </Link>{' '}
                for Registration
              </p>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};
export default CekParticipant;
