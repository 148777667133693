import React, { useState } from 'react';

export default function Form({ submittedForm }) {
  const [formData, setFormData] = useState();

  const [isError, setError] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (
      formData?.email === 'admin@ent.pens.ac.id' &&
      formData?.password === 'enteh18'
    ) {
      submittedForm();
    } else {
      setError(true);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
      <a
        href={'/'}
        className="flex items-center mb-6 text-2xl font-semibold text-white"
      >
        <img className="w-36" src="/logo/Logo ENT (Terang).svg" alt="logo" />
      </a>
      <div className="w-full bg-white rounded-lg shadow md:mt-0 sm:max-w-md xl:p-0">
        <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
          <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl">
            Welcome Back!
          </h1>
          <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit}>
            <div>
              <label
                htmlFor="email"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Email
              </label>
              <input
                type="email"
                name="email"
                id="email"
                className={`${
                  isError
                    ? 'border-red-500 focus:ring-red-500 focus:border-red-500'
                    : 'border-gray-300 focus:ring-teal-600 focus:border-teal-600'
                } bg-gray-50 border text-gray-900 rounded-lg  block w-full p-2.5`}
                placeholder="name@company.com"
                onChange={handleInputChange}
              />
              {isError && (
                <p className="mt-2 text-sm text-red-600 dark:text-red-500">
                  These credentials do not match our records.
                </p>
              )}
            </div>
            <div>
              <label
                htmlFor="password"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Password
              </label>
              <input
                type="password"
                name="password"
                id="password"
                placeholder="••••••••"
                className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-teal-600 focus:border-teal-600 block w-full p-2.5"
                onChange={handleInputChange}
              />
            </div>

            <button
              type="submit"
              className="w-full text-white bg-teal-500 hover:bg-teal-600 focus:ring-4 focus:outline-none focus:ring-teal-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
            >
              Sign in
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}
