import React, { useState } from "react";
import Content from "./Content";
import Form from "./Form";
import bgToTop from "../../../asset/pattern4-totop.svg";
import bgToBottom from "../../../asset/pattern4-tobottom.svg";
import DashboardNavbar from "./Navbar";
import Footer from "../../Common/Footer";

export default function FormPage() {
  const [formSubmitted, setFormSubmitted] = useState(false);

  const submittedForm = () => {
    setFormSubmitted(true);
  };

  return (
    <div className={`relative h-full text-gray-700 antialiased leading-none font-sans z-0 min-h-screen ${formSubmitted ? "bg-gray-100" : "bg-hero"}`}>
      <div className="flex flex-col absolute inset-0 justify-between -z-10">
        <div
          className={`bg-repeat-x ${formSubmitted ? "h-80" : "h-56"}`}
          style={{
            backgroundImage: `url(${formSubmitted ? bgToBottom : bgToTop})`,
            backgroundSize: "contain",
          }}
        ></div>
        <div
          className={`bg-repeat-x ${formSubmitted ? "h-80" : "h-56"}`}
          style={{
            backgroundImage: `url(${formSubmitted ? bgToTop : bgToBottom})`,
            backgroundSize: "contain",
          }}
        ></div>
      </div>

      {!formSubmitted ? (
        <Form submittedForm={submittedForm} />
      ) : (
        <>
          <DashboardNavbar />
          <Content formSubmitted={formSubmitted} />
          <Footer />
        </>
      )}
    </div>
  );
}
