import { Navbar } from 'flowbite-react';
import { Link } from 'react-scroll';
import { Link as RouterLink } from 'react-router-dom';

export default function NavbarWithCTAButton() {
  return (
    <Navbar className="bg-hero fixed w-full z-20 top-0 left-0  ">
      <nav className="bg-hero text-white fixed w-full z-20 top-0 left-0  ">
        <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
          <Navbar.Brand href="/" className="flex items-center">
            <img
              alt="ENT Logo"
              className="mr-3 h-6 sm:h-9"
              src="/logo/ENT (Terang).svg"
            />
            <span className="self-center whitespace-nowrap lg:text-xl md:text-md text-xs font-semibold text-white">
              EEPIS News and Network Team
            </span>
          </Navbar.Brand>

          <Navbar.Toggle />

          <Navbar.Collapse>
            <RouterLink to="/" className="md:text-lg text-md">
              <p className="text-white hover:text-yellow-700 cursor-pointer">
                Home
              </p>
            </RouterLink>
            <Link
              to="about"
              smooth={true}
              duration={500}
              className=" md:text-lg text-md"
            >
              <p className="text-white hover:text-yellow-700 cursor-pointer">
                About
              </p>
            </Link>
            <Link
              to="division"
              smooth={true}
              duration={500}
              className="md:text-lg text-md"
            >
              <p className="text-white hover:text-yellow-700 cursor-pointer">
                Division
              </p>
            </Link>
            <Link
              to="team"
              smooth={true}
              duration={500}
              className=" md:text-lg text-md"
            >
              <p className="text-white  hover:text-yellow-700 cursor-pointer">
                Team
              </p>
            </Link>
          </Navbar.Collapse>
        </div>
      </nav>
    </Navbar>
  );
}
