import React from 'react';
import { Link } from 'react-router-dom';

const NotfoundPage = () => {
  return (
    <section class="bg-hero dark:bg-gray-900 h-screen flex items-center">
      <div class="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
        <div class="mx-auto max-w-screen-sm text-center">
          <h1 class="mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-teal-500">
            404
          </h1>
          <p class="mb-4 text-3xl tracking-tight font-bold md:text-4xl text-white">
            Page Not Found.
          </p>
          <p class="mb-4 text-lg font-light text-gray-400">
            We're sorry, the page you requested could not be found, Please go
            back.{' '}
          </p>

          <Link
            className="inline-flex text-white bg-teal-600 hover:bg-teal-800 focus:ring-4 focus:outline-none focus:ring-teal-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:focus:ring-teal-900 my-4"
            to={'/'}
          >
            Kembali ke ent.pens.ac.id
          </Link>
        </div>
      </div>
    </section>
  );
};
export default NotfoundPage;
