import { Link } from 'react-router-dom';

export default function DashboardNavbar() {
  return (
    <nav className="bg-hero text-white">
      <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
        <Link
          to="/"
          className="flex items-center space-x-3 rtl:space-x-reverse"
        >
          <img src="/logo/ENT (Terang).svg" className="h-8" alt="ENT Logo" />
          <span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white hidden md:block">
            EEPIS News and Network Team
          </span>
        </Link>
        <div className="block w-auto" id="navbar-default">
          <ul className="font-medium flex p-0 rounded-lg flex-row space-x-8 mt-0">
            <li>
              <Link
                to="/"
                className="block rounded bg-transparent p-0 hover:text-teal-500"
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                to="/requirement"
                className="block rounded bg-transparent p-0 hover:text-teal-500"
              >
                Requirement
              </Link>
            </li>
            <li>
              <Link
                to="/participant"
                className="block rounded bg-transparent p-0 hover:text-teal-500"
              >
                Participant
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}
